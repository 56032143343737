import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { isIOS, isAndroid } from 'react-device-detect'
import detectBrowserLanguage from 'detect-browser-language'

import './styles.css'

import BgImage from './res/images/wippy_download_page_bg_ko.jpg'

import WIPPYDownloadedPageTextSvg from './res/images/wippy_download_page_text_ko.svg'
import WIPPYDownloadPageGoogleBtnSvg from './res/images/wippy_download_page_google_btn.svg'
import WIPPYDownloadPageAppStoreBtnSvg from './res/images/wippy_download_page_app_store_btn.svg'
import WIPPYDownloadPageGoogleAppStoreTextSvg from './res/images/wippy_download_page_google_app_store_text.svg'
import WIPPYDownloadPageAppStoreTextSvg from './res/images/wippy_download_page_app_store_text.svg'
import WIPPYDownloadPageGoogleTextSvg from './res/images/wippy_download_page_google_text.svg'

// TW
import WIPPYDownloadPageTextTWSvg from './res/images/wippy_download_page_text_zh.svg'
import WIPPYDownloadPageGoogleAppStoreTextTWSvg from './res/images/wippy_download_page_google_app_store_text_zh.svg'
import WIPPYDownloadPageAppStoreTextTWSvg from './res/images/wippy_download_page_app_store_text_zh.svg'
import WIPPYDownloadPageGoogleTextTWSvg from './res/images/wippy_download_page_google_text_zh.svg'

// EN
import WIPPYDownloadPageTextENSvg from './res/images/wippy_download_page_text_en.svg'
import WIPPYDownloadPageGoogleAppStoreTextENSvg from './res/images/wippy_download_page_google_app_store_text_en.svg'
import { LANG } from './const'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Spoqa Han Sans", sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
    background: #ffffff url(${BgImage}) no-repeat center;
    background-size: cover;
  }

  #root {
    width: 100%;
    height: 100%;
  }
`

function App() {
  const [lang, setLang] = React.useState(LANG.KR)

  const handleIOS = () => {
    window.location.replace('wippy://')
    setTimeout(function() {
      window.location.replace('https://itunes.apple.com/kr/app/apple-store/id1261209014')
    }, 1000)
  }

  const handleAndroidOS = () => {
    window.location.replace('wippy://')
    setTimeout(function() {
      window.location.replace('intent://wippy/#Intent;package=net.nrise.wippy;scheme=https;end')
    }, 1000)
  }

  React.useEffect(() => {
    if (isAndroid) {
      handleAndroidOS()
    } else if (isIOS) {
      handleIOS()
    }
  }, [])

  React.useEffect(() => {
    const language = detectBrowserLanguage()

    const languageEn = language.split(',').findIndex((v) => v.includes('en-'))
    const languageZh = language.split(',').findIndex((v) => v.includes('zh-'))
    const languageKr = language.split(',').findIndex((v) => v.includes('ko'))

    if (languageKr === 0) {
      if (languageZh > 0 || languageEn > 0) {
        if (languageZh < languageEn) {
          setLang(LANG.TW)
        } else {
          setLang(LANG.EN)
        }
      }
      setLang(LANG.KR)
    } else if (languageZh === 0) {
      if (languageKr > 0 || languageEn > 0) {
        if (languageKr < languageEn) {
          setLang(LANG.KR)
        } else {
          setLang(LANG.EN)
        }
      }
      setLang(LANG.TW)
    } else if (languageEn === 0) {
      if (languageZh > 0 || languageKr > 0) {
        if (languageKr < languageZh) {
          setLang(LANG.KR)
        } else {
          setLang(LANG.TW)
        }
      }
      setLang(LANG.EN)
    } else {
      setLang(LANG.EN)
    }
  }, [])

  if (lang === '') {
    return <div />
  }

  return (
    <>
      <GlobalStyle />
      <div className='flex-box wippy-container'>
        <div className='flex-box title-text-area'>
          {lang === LANG.KR && <img src={WIPPYDownloadedPageTextSvg} />}
          {lang === LANG.TW && <img src={WIPPYDownloadPageTextTWSvg} />}
          {lang === LANG.EN && <img src={WIPPYDownloadPageTextENSvg} />}
        </div>
        <div className='flex-box store-btn-area'>
          <div className='flex-box flex-box-row btn'>
            {isAndroid && (
              <img
                className='flex-box google'
                src={WIPPYDownloadPageGoogleBtnSvg}
                style={{ cursor: 'pointer' }}
                onClick={() => handleAndroidOS()}
              />
            )}
            {isIOS && (
              <img
                className='flex-box app-store'
                src={WIPPYDownloadPageAppStoreBtnSvg}
                style={{ cursor: 'pointer' }}
                onClick={() => handleIOS()}
              />
            )}
            {!isAndroid && !isIOS && (
              <>
                <img
                  className='flex-box google'
                  src={WIPPYDownloadPageGoogleBtnSvg}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    (window.location.href = 'https://play.google.com/store/apps/details?id=net.nrise.wippy')
                  }
                />
                <img
                  className='flex-box app-store'
                  src={WIPPYDownloadPageAppStoreBtnSvg}
                  style={{ cursor: 'pointer' }}
                  onClick={() => (window.location.href = 'https://itunes.apple.com/kr/app/apple-store/id1261209014')}
                />
              </>
            )}
          </div>
          <div className='flex-box text'>
            {/* */}
            {!isAndroid && !isIOS && lang === LANG.KR &&
            <img className='flex-box google-app-store' src={WIPPYDownloadPageGoogleAppStoreTextSvg} />}
            {!isAndroid && !isIOS && lang === LANG.TW &&
            <img className='flex-box google-app-store' src={WIPPYDownloadPageGoogleAppStoreTextTWSvg} />}
            {!isAndroid && !isIOS && lang === LANG.EN &&
            <img className='flex-box google-app-store' src={WIPPYDownloadPageGoogleAppStoreTextENSvg} />}
            {/* */}
            {isIOS && lang === LANG.KR && <img className='flex-box app-store'
                                               src={WIPPYDownloadPageAppStoreTextSvg} />}
            {isIOS && lang === LANG.TW && <img className='flex-box app-store'
                                               src={WIPPYDownloadPageAppStoreTextTWSvg} />}
            {/*{isIOS && lang === LANG.EN && <img className='flex-box app-store'*/}
            {/*                                   src={WIPPYDownloadPageGoogleTextENSvg} />}*/}
            {isAndroid && lang === LANG.KR && <img className='flex-box google'
                                                   src={WIPPYDownloadPageGoogleTextSvg} />}
            {isAndroid && lang === LANG.TW && <img className='flex-box google'
                                                   src={WIPPYDownloadPageGoogleTextTWSvg} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default App
