import React from 'react';
import { Routes, Route } from "react-router-dom";
import App from "./App";

const RouteMain = () => {
    return (
        <Routes>
            <Route index element={<App />} />
        </Routes>
    );
};

export default RouteMain;